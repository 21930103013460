import {Keys} from "@/constants/key";
import {TaskConstants} from "@/constants/task-constants";
import {UIStyle, TextStyle, LoadingAnimStyle} from "@/scenes.ui/types";

export abstract class Urls {
    public static CURRENT_UI_STYLE: UIStyle = UIStyle.Ordinary;
    public static CURRENT_TEXT_STYLE: TextStyle = TextStyle.Ordinary;
    public static CURRENT_LOADING_ANIM_STYLE: LoadingAnimStyle = LoadingAnimStyle.Ordinary;

    public static IMAGES: Map<string, string | string[]> = new Map<string, string | string[]>([
        [Keys.HINTER, 'assets/ui/hinter.png'],
        [Keys.IMG_ARC, 'assets/ui/arc.png'],
        [Keys.BIG_CAT_MALE, 'assets/creatures/cat_m.png'],
        [Keys.BIG_CAT_FEMALE, 'assets/creatures/cat_f.png'],
        [Keys.HEROES_COMMENT_3, 'assets/ui/heros_comment_3.png'],
        [Keys.HEROES_COMMENT_2, 'assets/ui/heros_comment_2.png'],
        [Keys.HEROES_COMMENT, 'assets/ui/heros_comment.png'],
        [Keys.IMG_SNOW, 'assets/ui/snow.png'],
        [Keys.IMG_SPARK1, 'assets/particles/spark1.png'],
        [Keys.IMG_SPARK2, 'assets/particles/spark2.png'],
        [Keys.IMG_SPARK3, 'assets/particles/spark3.png'],
        [Keys.IMG_SNOW, 'assets/particles/snow.png'],
        [Keys.UI_BG, 'assets/ui/uiBg.png'],
        [Keys.IMG_RES_GOLD, 'assets/loot/res_gold.png'],
        [Keys.IMG_RES_GOLD_NO_SHADOW, 'assets/loot/res_gold_no_shadow.png'],
        [Keys.IMG_RES_CRYSTAL, 'assets/loot/res_crystal_red.png'],
        [Keys.IMG_RES_ELIXIR, 'assets/loot/res_crystal_yellow.png'],
        [Keys.IMG_RES_ELIXIR_NO_SHADOW, 'assets/loot/res_crystal_yellow_no_shadow.png'],
        [Keys.IMG_RES_MITHRIL, 'assets/loot/res_gem.png'],
        [Keys.P6_RU, 'assets/ui/part6_ru.png'],
        [Keys.P7_RU, 'assets/ui/part7_ru.png'],
        [Keys.P6_EN, 'assets/ui/part6_en.png'],
        [Keys.P7_EN, 'assets/ui/part7_en.png'],
        [Keys.IMG_RES_SULFUR, 'assets/loot/res_sulfur.png'],
        [Keys.IMG_RES_CAVIAR, 'assets/loot/res_caviar.png'],
        [Keys.IMG_RES_GEM_NO_SHADOW, 'assets/loot/gem_no_shadow.png'],
        [Keys.BRIDGE, 'assets/bg/bridge.png'],
        [Keys.BRIDGE_2, 'assets/bg/bridge_2.png'],
        [Keys.GATE_1, 'assets/bg/gate_1.png'],
        [Keys.GATE_2, 'assets/bg/gate_2.png'],
        [Keys.ARCH_1, 'assets/bg/arch_1.png'],
        [Keys.ARCH_2, 'assets/bg/arch_2.png'],
        [Keys.TR_1, 'assets/bg/tr_1.png'],
        [Keys.TR_2, 'assets/bg/tr_2.png'],
        [Keys.TR_3, 'assets/bg/tr_3.png'],
        [Keys.TR_4, 'assets/bg/tr_4.png'],
        [Keys.TR_5, 'assets/bg/tr_5.png'],
        [Keys.PLACE_SHADOW, 'assets/shadow.png'],
        [Keys.PROFILE_BACK, 'assets/bg/creature_back.jpg'],
        [Keys.SHOP_BACK, 'assets/bg/shop-back.jpg'],
        [Keys.TASK_BACK, 'assets/bg/task-back.jpg'],
        [Keys.TASK_BOTTOM, 'assets/bg/task-bottom.jpg'],
        [Keys.SHOP_BOTTOM, 'assets/bg/shop-bottom.jpg'],
        [Keys.IMG_CLICK_FLASH, 'assets/click_flash.png'],
        [Keys.IMG_CROSSHAIR, 'assets/crosshair.png'],
        [Keys.IMG_CROSS_SNOW, 'assets/cross_snow.png'],
        [Keys.IMG_PARTICLE_CIRCLE, 'assets/particles/circle.png'],
        [Keys.IMG_SKULL_ICON, 'assets/skull_icon.png'],
        [Keys.IMG_GREY_BACKGROUND, 'assets/bg/grey_background.png'],
        [Keys.IMG_PLAYER_TURN, 'assets/your_turn.png'],
        [Keys.IMG_LESSON_HAND, 'assets/hand.png'],
        [Keys.IMG_LESSON_HAND_SHADOW, 'assets/hand_shadow.png'],
        [Keys.IMG_REWARD_EXPERIENCE, 'assets/battle_rewards/br_experience.png'],
        [Keys.CL_GEAR_RESOURCE, 'assets/clan/clan-gear-resource.png'],
        [Keys.IMG_REWARD_CUPS, 'assets/battle_rewards/br_cups.png'],
        [Keys.IMG_REWARD_GEM, 'assets/battle_rewards/br_gem.png'],
        [Keys.IMG_REWARD_CRYSTAL_RED, 'assets/battle_rewards/br_crystal_red.png'],
        [Keys.IMG_REWARD_GOLD, 'assets/battle_rewards/br_gold.png'],
        [Keys.IMG_REWARD_CRYSTAL_YELLOW, 'assets/battle_rewards/br_crystal_yellow.png'],
        [Keys.IMG_CARD_SHADE, 'assets/card_shade.png'],
        [Keys.IC_100_MITHRIL, 'assets/ic100x100/ic_mithril.png'],
        [Keys.IC_100_CAVIAR, 'assets/ic100x100/ic_caviar.png'],
        [Keys.IC_110_MITHRIL, 'assets/ic100x100/ic_mith_110.png'],
        [Keys.IC_100_AMETIST, 'assets/ic100x100/ic_ametist.png'],
        [Keys.IC_BR_CUP, 'assets/ic100x100/ic_br_cups.png'],
        [Keys.KM_HEAD, 'assets/ui/km.png'],
        [Keys.ARENA_HEAD, 'assets/arena/head.png'],
        [Keys.ARENA_CUP, 'assets/arena/cup.png'],
        [Keys.UI_BOTTOM_MENU, 'assets/menu-x.jpg'],
        [Keys.GREEN_HORIZONTAL_BUTTON, 'assets/button.png'],
        [Keys.UI_SHOP_X, 'assets/shop/shop-x.png'],
        [Keys.UI_ART_BASIC_WINDOW, `assets/windows${Urls.CURRENT_UI_STYLE}/window-long${Urls.CURRENT_UI_STYLE}${Urls.CURRENT_UI_STYLE}.png`],
        [Keys.UI_WINDOW_MIDDLE, `assets/windows${Urls.CURRENT_UI_STYLE}/window-middle${Urls.CURRENT_UI_STYLE}${Urls.CURRENT_UI_STYLE}.png`],
        [Keys.UI_WINDOW_BIG, `assets/windows${Urls.CURRENT_UI_STYLE}/window-big${Urls.CURRENT_UI_STYLE}${Urls.CURRENT_UI_STYLE}.webp`],
        [Keys.UI_WINDOW_HORIZONTAL, `assets/windows${Urls.CURRENT_UI_STYLE}/window-horiz${Urls.CURRENT_UI_STYLE}${Urls.CURRENT_UI_STYLE}.png`],
        [Keys.UI_WINDOW_MIDDLE_BIG, `assets/windows${Urls.CURRENT_UI_STYLE}/window-middle-big${Urls.CURRENT_UI_STYLE}${Urls.CURRENT_UI_STYLE}.png`],
        [Keys.UI_ART_WINDOW_FORCES, `assets/windows/window-force.png`],
        [Keys.UI_NOTIF_PANEL, 'assets/windows/notif-panel.png'],
        [Keys.UI_WINDOW_BIG_2, `assets/windows/window-big-2.webp`],
        [Keys.UI_WARNING, 'assets/warning_icon.png'],
        [Keys.UI_SHOP_ARTIFACT_EFFECT_DEFENCE_ICON, 'assets/ratings-shield.png'],
        [Keys.UI_SHOP_ARTIFACT_EFFECT_ATTACK_ICON, 'assets/ratings-sword.png'],
        [Keys.UI_SHOP_ARTIFACT_EFFECT_HEALTH_ICON, 'assets/arena/heart.png'],
        [Keys.CLAN_RATING, 'assets/clan/rating.png'],
        [Keys.CLAN_BUILDING, 'assets/clan/clan-building.png'],
        [Keys.CLAN_ONLINE, 'assets/clan/online.png'],
        [Keys.CLAN_OFFLINE, 'assets/clan/offline.png'],
        [Keys.CLAN_GEAR, 'assets/clan/gear.png'],
        [Keys.CL_SNOWFLAKE, 'assets/clan/snowflake.png'],
        [Keys.CL_INVITE, 'assets/clan/cl_invite.png'],
        [Keys.CL_KICK, 'assets/clan/cl_kick.png'],
        [Keys.CL_UP, 'assets/clan/cl_up.png'],
        [Keys.CL_DOWN, 'assets/clan/cl_down.png'],
        [Keys.CAMPAIGN_RIBBON, 'assets/offer/ribbonOrange.png'],
        [Keys.CAMPAIGN_RIBBON_BLUE, 'assets/offer/ribbonBlue.png'],
        [Keys.RIBBON_FOR_BUNCH, 'assets/offer/ribbonForBunch.png'],
        [Keys.BATTLE_TOP_BOTTOM, 'assets/ui/battle_top.png'],
        [Keys.OFFER_RIBBON, 'assets/offer/discountRibbon.png'],
        [Keys.OFFER_RIBBON_EN, 'assets/offer/discountRibbonEn.png'],
        [Keys.HINT_BUTTON, `assets/ui${Urls.CURRENT_UI_STYLE}/hint${Urls.CURRENT_UI_STYLE}.png`],
        [Keys.OFFER_LIGHT_BACK, 'assets/offer/lightBack.png'],
        [Keys.OFFER_SHOP_ICON, 'assets/offer/shopIcon.png'],
        [Keys.OFFER_SHOP_ICON, 'assets/offer/shopIcon.png'],
        [Keys.OFFER_RIBBON_FAT, 'assets/offer/ribbonFat.png'],
        [Keys.IMG_VICTORY_KNIGHT, 'assets/ui/victory_knight.png'],
        [Keys.FRM_RADIO_OUT, 'assets/forum/radio_out.png'],
        [Keys.FRM_RADIO_IN, 'assets/forum/radio_in.png'],
        [Keys.FRM_UP, 'assets/forum/up.png'],
        [Keys.FRM_UP_GREEN, 'assets/forum/up_green.png'],
        [Keys.FRM_TAG_BG, 'assets/forum/tag-bg.png'],
        [Keys.FRM_CLOSE, 'assets/forum/close.png'],
        [Keys.FRM_PIN_WHITE, 'assets/forum/pin-white.png'],
        [Keys.FRM_PIN_GREEN, 'assets/forum/pin-green.png'],
        [Keys.FRM_PIN_ROUND, 'assets/forum/pin-round.png'],
        [Keys.FRM_COPY_ROUND, 'assets/forum/copy-round.png'],
        [Keys.FRM_REPLY_ARROW, 'assets/reply-arrow.png'],
        [Keys.FRM_PAGING_BUTTON, 'assets/forum/paging-button1.png'],
        [Keys.FRM_PAGING_BUTTON2, 'assets/forum/paging-button2.png'],

        [Keys.CHEST_SILVER_CLOSED, 'assets/chest/silverChest.png'],
        [Keys.CHEST_GOLDEN_CLOSED, 'assets/chest/goldenChest.png'],
        [Keys.CHEST_AZURE_CLOSED, 'assets/chest/azureChest.png'],
        [Keys.CHEST_LAVA_CLOSED, 'assets/chest/lavaChest.png'],
        [Keys.CHEST_AMBER_CLOSED, 'assets/chest/amberChest.png'],
        [Keys.CHEST_JADE_CLOSED, 'assets/chest/jadeChest.png'],
        [Keys.CHEST_SILVER_OPENED, 'assets/chest/silverChestOpened.png'],
        [Keys.CHEST_GOLDEN_OPENED, 'assets/chest/goldenChestOpened.png'],
        [Keys.CHEST_AZURE_OPENED, 'assets/chest/azureChestOpened.png'],
        [Keys.CHEST_LAVA_OPENED, 'assets/chest/lavaChestOpened.png'],
        [Keys.CHEST_AMBER_OPENED, 'assets/chest/amberChestOpened.png'],
        [Keys.CHEST_JADE_OPENED, 'assets/chest/jadeChestOpened.png'],
        [Keys.CHEST_GOLDEN_IC, 'assets/chest/goldenChestIcon.png'],
        [Keys.CHEST_SILVER_IC, 'assets/chest/silverChestIcon.png'],
        [Keys.CHEST_AZURE_IC, 'assets/chest/azureChestIcon.png'],
        [Keys.CHEST_LAVA_IC, 'assets/chest/lavaChestIcon.png'],
        [Keys.CHEST_AMBER_IC, 'assets/chest/amberChestIcon.png'],
        [Keys.CHEST_JADE_IC, 'assets/chest/jadeChestIcon.png'],

        [Keys.CHAIN_DARK_BLACK, 'assets/chain/dark_black.png'],
        [Keys.CHAIN_DARK_GREY, 'assets/chain/dark_grey.png'],
        [Keys.CHAIN_LIGHT_GREEN, 'assets/chain/light_green.png'],
        [Keys.CHAIN_LIGHT_YELLOW, 'assets/chain/light_yellow.png'],

        [Keys.UI_BACKPACK_ICON, 'assets/profile/backpack-icon.png'],
        [Keys.UI_MANUAL_ICON, 'assets/profile/manual-icon.png'],
        [Keys.UI_BACKPACK_WINDOW, 'assets/windows/backpack-window.png'],
        [Keys.UI_BACKPACK_INFO, 'assets/profile/backpack-info-panel.png'],
        [Keys.UI_BACKPACK_SLOTS_ICON, 'assets/profile/backpack-slots-icon.png'],
        [Keys.UI_BACKPACK_CORNER_SHADOW, 'assets/profile/backpack-corner-shadow.png'],

        [Keys.UI_DISCOUNT_ENABLED, 'assets/ui/discount-enabled.png'],
        [Keys.UI_DISCOUNT_DISABLED, 'assets/ui/discount-disabled.png'],

        [Keys.UI_POWER_UP_BG_3, 'assets/ui/power-up-bg-3.png'],
        [Keys.UI_POWER_UP_ARROW, 'assets/ui/power-up-arrow.png'],
        [Keys.UI_POWER_UP_INFO_TOP, 'assets/ui/power-up-info-top.png'],
        [Keys.UI_POWER_UP_INFO_MIDDLE, 'assets/ui/power-up-info-middle.png'],
        [Keys.UI_POWER_UP_INFO_BOTTOM, 'assets/ui/power-up-info-bottom.png'],

        [Keys.UI_MANUAL_QUESTION_GREEN, 'assets/manual/question-green.png'],
        [Keys.UI_MANUAL_BLUE_FADE, 'assets/manual/blue-fade.png'],
        [Keys.UI_MANUAL_TOP_FRAME, 'assets/manual/top-frame.png'],

        [Keys.UI_PROFILE_FORCES_INFO_PANEL, 'assets/profile/forces-info-panel.png'],
        [Keys.UI_PROFILE_FORCES_INFO_PANEL_MIDDLE, 'assets/profile/forces-info-panel-middle.png'],
        [Keys.UI_PROFILE_FORCES_INFO_PANEL_EDGE, 'assets/profile/forces-info-panel-edge.png'],
        [Keys.UI_RED_LINER, 'assets/profile/red-liner.png'],
        [Keys.UI_GOLD_DOT, 'assets/profile/gold-dot.png'],
        [Keys.UI_PROFILE_FORCES_INFO_PANEL_BOTTOM, 'assets/profile/forces-info-panel-bottom.png'],
        [Keys.UI_PROFILE_CHAR_BOTTOM_STRIPE, 'assets/profile/char-bottom-stripe.png'],
        [Keys.UI_PROFILE_CHAR_BOTTOM_STRIPE_2, 'assets/profile/char-bottom-stripe-2.png'],
        [Keys.UI_PROFILE_CHAR_BOTTOM_STRIPE_GRAY, 'assets/profile/char-bottom-stripe-gray.png'],
        [Keys.UI_PROFILE_FORCES_INFO_POINTER, 'assets/profile/forces-info-pointer.png'],
        [Keys.UI_PROFILE_EVIL_SIGN, 'assets/profile/evil-sign.png'],
        [Keys.UI_PROFILE_EVIL_SIGN_BIG, 'assets/profile/evil-sign-big.png'],
        [Keys.UI_PROFILE_GOOD_SIGN, 'assets/profile/good-sign.png'],
        [Keys.UI_PROFILE_GOOD_SIGN_BIG, 'assets/profile/good-sign-big.png'],
        [Keys.UI_GLASS, 'assets/profile/glass.png'],
        [Keys.UI_COPY, 'assets/profile/copy.png'],
        [Keys.UI_SHARE, 'assets/profile/share.png'],
        [Keys.UI_SHARE_OK, 'assets/profile/share-ok.png'],
        [Keys.UI_SHARE_VK, 'assets/profile/share-vk.png'],
        [Keys.UI_SHARE_FB, 'assets/profile/share-fb.png'],
        [Keys.UI_BLESSING_SIGN, 'assets/profile/blessing.png'],

        [Keys.EMPTY, 'assets/empty.png'],

        [Keys.ICON_HEROES, 'assets/profile/icon-heroes.png'],
        [Keys.ICON_CARDS, 'assets/profile/icon-cards.png'],
        [Keys.CARD_ELEPHANT, 'assets/card/elephant.png'],
        [Keys.CARD_ELEPHANT_SMALL, 'assets/card/elephant-small.png'],
        [Keys.CARD_SNAKE, 'assets/card/snake.png'],
        [Keys.CARD_SNAKE_SMALL, 'assets/card/snake-small.png'],
        [Keys.CARD_EAGLE, 'assets/card/eagle.png'],
        [Keys.CARD_EAGLE_SMALL, 'assets/card/eagle-small.png'],

        [Keys.TASK_BIRTHDAY_DRONT, 'assets/edu/task-dront.png'],
        [Keys.TASK_BIRTHDAY_QUEEN, 'assets/edu/task-queen.png'],

        ['black', 'assets/edu/black.png'],
        ['road_rainbow', 'assets/bg/road_rainbow.jpg'],
        ['rain_turn_right', 'assets/bg/rain_turn_right.jpg'],
        ['long_bridge', 'assets/bg/longBridge.jpg'],
        ['island', 'assets/bg/island.jpg'],
        [Keys.IMG_BLACK_BACKGROUND, 'assets/bg/black_background.png'],
        [Keys.ENEMY_LEV_5, 'assets/forces/enemy_5.png'],
        ['blue_7', 'assets/forces/blue_7.png'],
        ['red_7', 'assets/forces/red_7.png'],
        ['gold_7', 'assets/forces/gold_7.png'],
        ['gold_5', 'assets/forces/gold_5.png'],

        [Keys.UI_PUSH_CHANGE, 'assets/ui/push.png'],
        [Keys.UI_QA_CHANGE, 'assets/ui/qa.png'],

        [Keys.UI_PROFILE_GIFT_GEMS, 'assets/profile/profile_gift_gems.png'],

        [Keys.IMG_BLOCK_YELLOW, 'assets/ui/contour_block_2.png'],
        [Keys.IMG_BLOCK_RED, 'assets/ui/contour_block_3.png'],
        ['g_hand', 'assets/place/g_hand.png'],

        [Keys.IMG_POISON, 'assets/ui/poison.png'],
        [Keys.IMG_KOSCHEY, 'assets/ui/koschey.png'],
        [Keys.UI_KOSCHEY_BAR_OUTER, 'assets/ui/koschey_outer.png'],

        [Keys.IMG_LUCK_EFFECT_CLOVER, 'assets/ui/clover.png'],

        [TaskConstants.TASK_APPLE, 'assets/gain/task-apple.png'],
        [TaskConstants.TASK_BOOKS, 'assets/gain/task-books.png'],
        [TaskConstants.TASK_PUMPKIN, 'assets/gain/task-pumpkin.png'],

        [Keys.FERN_SMALL, 'assets/gain/fern-small.png'],

        ['b_taitus', 'assets/creatures/taitus.png'],
        ['b_squirrel', 'assets/creatures/squirrel.png'],
        ['b_tree', 'assets/creatures/tree.png'],
        ['b_luckydrag', 'assets/creatures/luckydrag.png'],
        ['b_kitty', 'assets/creatures/kitty.png'],
        ['b_dark_serra', 'assets/creatures/dark_serra.png'],
        ['b_battle-teapot-1', 'assets/creatures/battle-teapot-1.png'],
        ['b_battle-teapot-2', 'assets/creatures/battle-teapot-2.png'],
        ['b_battle-teapot-3', 'assets/creatures/battle-teapot-3.png'],
        ['b_battle-card-1', 'assets/creatures/battle-card-1.png'],
        ['b_battle-card-2', 'assets/creatures/battle-card-2.png'],
        ['b_battle-card-3', 'assets/creatures/battle-card-3.png'],

        ['b_nps-barbarian-1', 'assets/creatures/nps-barbarian-1.png'],
        ['b_kiki', 'assets/creatures/kiki.png'],
        ['b_nps-barbarian-2', 'assets/creatures/nps-barbarian-2.png'],
        ['b_nps-barbarian-3', 'assets/creatures/nps-barbarian-3.png'],
        ['b_nps-fishman', 'assets/creatures/nps-fishman.png'],

        ['b_kupala-beast-1', 'assets/creatures/kupala-beast-1.png'],
        ['b_kupala-beast-2', 'assets/creatures/kupala-beast-2.png'],
        ['b_kupala-beast-3', 'assets/creatures/kupala-beast-3.png'],
        ['b_defiler_blue', 'assets/creatures/defiler_blue.png'],
        ['b_defiler_green', 'assets/creatures/defiler_green.png'],
        ['b_defiler_red', 'assets/creatures/defiler_red.png'],
        ['b_cow_1', 'assets/creatures/cow_1.png'],
        ['b_cow_2', 'assets/creatures/cow_2.png'],
        ['b_elf_shal_1', 'assets/creatures/elf_shal_1.png'],
        ['b_elf_shal_2', 'assets/creatures/elf_shal_2.png'],
        ['b_elf_shal_3', 'assets/creatures/elf_shal_3.png'],
        ['b_hed_hog', 'assets/creatures/hed_hog.png'],
        ['b_owl_1', 'assets/creatures/owl_1.png'],
        ['b_owl_2', 'assets/creatures/owl_2.png'],
        ['b_owl_3', 'assets/creatures/owl_3.png'],
        ['b_hlw_1', 'assets/creatures/hlw_1.png'],
        ['b_hlw_2', 'assets/creatures/hlw_2.png'],
        ['b_hlw_3', 'assets/creatures/hlw_3.png'],
        [Keys.ADD_MUSHROOM, 'assets/consumables/add-mushroom.png'],

        ['arrow_l', 'assets/ui/arrow_l.png'],
        ['arrow_r', 'assets/ui/arrow_r.png'],
        ['rab-edu', 'assets/edu/rab-edu.png'],
        [Keys.UI_ARROW, 'assets/ui/arrow.png'],

        [Keys.SKIP_MV, `assets/ui${Urls.CURRENT_UI_STYLE}/skip${Urls.CURRENT_UI_STYLE}.png`],

        [Keys.RAB_RU, 'assets/start/rab_letter_ru.png'],
        [Keys.RAB_EN, 'assets/start/rab_letter_en.png'],
        ['p-sister1', 'assets/place/p-sister1.png'],
        ['p-sister2', 'assets/place/p-sister2.png'],
        ['p-brother', 'assets/place/p-brother.png'],

        ['pack_1', 'assets/shop/pack_1.jpg'],
        ['pack_2', 'assets/shop/pack_2.jpg'],
        ['pack_3', 'assets/shop/pack_3.jpg'],
        ['pack_4', 'assets/shop/pack_4.jpg'],
        ['pack_5', 'assets/shop/pack_5.jpg'],
        ['pack_6', 'assets/shop/pack_6.jpg'],
        ['pack_7', 'assets/shop/pack_7.jpg'],
        ['pack_8', 'assets/shop/pack_8.jpg'],
        ['pack_9', 'assets/shop/pack_9.jpg'],
        ['pack_10', 'assets/shop/pack_10.jpg'],
        ['pack_11', 'assets/shop/pack_11.jpg'],
        ['pack_12', 'assets/shop/pack_12.jpg'],
        ['chain_1', 'assets/shop/chain_1.jpg'],
        ['chain_2', 'assets/shop/chain_2.jpg'],
        ['chain_3', 'assets/shop/chain_3.jpg'],
        ['chain_4', 'assets/shop/chain_4.jpg'],
        ['chain_5', 'assets/shop/chain_5.jpg'],
        ['chain_6', 'assets/shop/chain_6.jpg'],
        ['chain_7', 'assets/shop/chain_7.jpg'],
        ['chain_ny_1', 'assets/shop/chain_ny_1.jpg'],
        ['chain_ny_2', 'assets/shop/chain_ny_2.jpg'],
        ['chain_ny_3', 'assets/shop/chain_ny_3.jpg'],
        ['pack_ny_1', 'assets/shop/pack_ny_1.jpg'],
        ['pack_ny_2', 'assets/shop/pack_ny_2.jpg'],
        ['pack_ny_3', 'assets/shop/pack_ny_3.jpg'],
        ['pack_ny_4', 'assets/shop/pack_ny_4.jpg'],
        ['pack_mar_1', 'assets/shop/pack_mar_1.jpg'],
        ['pack_mar_2', 'assets/shop/pack_mar_2.jpg'],
        ['pack_mar_3', 'assets/shop/pack_mar_3.jpg'],
        ['pack_mar_4', 'assets/shop/pack_mar_4.jpg'],
        ['pack_mar_5', 'assets/shop/pack_mar_5.jpg'],
        ['pack_mar_6', 'assets/shop/pack_mar_6.jpg'],
        ['darkness_3', 'assets/shop/darkness_3.png'],
        ['elixir-1', 'assets/shop/elixir-1.png'],
        ['crystal-1', 'assets/shop/crystal-1.png'],
        ['caviar-1', 'assets/shop/caviar-1.png'],
        ['sword1', 'assets/chain/sword1.png'],
        ['gold-1', 'assets/shop/gold-1.png'],
        ['caviar_no_shadow', 'assets/ui/res_caviar_no_shadow.png'],
    ]);

    public static FRAME_CONFIGS: Map<string, any> = new Map<string, any>();

    public static ATLASES_WEBP: Set<string> = new Set<string>([
        Keys.ATLAS_PORTALS
    ]);

    public static IMAGES_WEBP: Set<string> = new Set<string>([
        'bb_1', 'bb_2', 'bb_3', 'bb_4', 'bb_5', 'bb_6', 'bb_7', 'bb_8', 'bbm_1', 'bbm_3', 'bbm_1b', 'bb_9', 'bb_cave', 'bb_keeper', 'bb_11', 'bb_12', 'bb_13'
    ]);

    public static ATLASES: Map<string, string> = new Map<string, string>([
        [Keys.ATLAS_NG_25, 'assets/event/ng_25'],
        [Keys.ATLAS_WO, 'assets/place/wo'],
        [Keys.ATLAS_SKILLS, 'assets/portal/sk_place'],
        [Keys.ATL_PACK_SKILLS, 'assets/shop/pack_skills'],
        [Keys.ATLAS_PROFILE, 'assets/profile/prof'],
        [Keys.ATLAS_GIFTS, 'assets/gifts/gifts'],
        [Keys.ATLAS_GIFTS_2, 'assets/gifts/gifts2'],
        [Keys.ATLAS_GIFTS_3, 'assets/gifts/gifts3'],
        [Keys.ATLAS_GIFTS_4, 'assets/gifts/gifts4'],
        [Keys.ATLAS_GIFTS_5, 'assets/gifts/gifts5'],
        [Keys.ATLAS_GIFTS_6, 'assets/gifts/gifts6'],
        [Keys.ATLAS_GIFTS_7, 'assets/gifts/gifts7'],
        [Keys.ATLAS_GIFTS_8, 'assets/gifts/gifts8'],
        [Keys.ATLAS_GIFTS_9, 'assets/gifts/gifts9'],
        [Keys.ATLAS_CHAIN, 'assets/chain/chain_3'],
        [Keys.ATLAS_EDU, 'assets/edu/edu-1g'],
        [Keys.ATLAS_EDU_2, 'assets/edu/edu-2'],
        [Keys.ATLAS_EDU_3, 'assets/edu/edu-3'],
        [Keys.ATLAS_EDU_6, 'assets/edu/edu-6'],
        [Keys.ATLAS_EDU_7, 'assets/edu/edu-7'],
        [Keys.ATLAS_EDU_SDQ, 'assets/edu/edu-sdq'],
        [Keys.ATLAS_LEAGUE, 'assets/arena/league'],
        [Keys.ATLAS_LEAGUE_SMALL, 'assets/arena/league_small'],
        [Keys.ATLAS_CREATURES_ROAD, 'assets/creatures/creatures-road'],
        [Keys.ATLAS_CREATURES_BATTLE_1, 'assets/creatures/creatures-battle-1-f'],
        [Keys.ATLAS_CREATURES_BATTLE_0, 'assets/creatures/creatures-battle-0-d'],
        [Keys.ATLAS_BATTLE_CATS, 'assets/creatures/cats-battle'],
        [Keys.ATLAS_BATTLE_AM, 'assets/creatures/am-battle-b'],
        [Keys.ATLAS_BATTLE_WT, 'assets/creatures/wt-battle'],
        [Keys.ATLAS_ANGELS, 'assets/creatures/angels'],
        [Keys.ATLAS_TRIPLE_BATTLES, 'assets/creatures/trb-creatures'],
        [Keys.ATLAS_SIDE_QUESTS, 'assets/creatures/sdq-creatures'],
        [Keys.ATLAS_ROAD_CATS, 'assets/creatures/cats-road'],
        [Keys.ATLAS_BOOST, 'assets/shop/boost'],
        [Keys.ATLAS_BANK, 'assets/shop/bank'],
        [Keys.ATLAS_BANK_2, 'assets/shop/bank2_b'],
        [Keys.ATLAS_SHOP_UI, 'assets/shop/ui'],
        [Keys.ATLAS_PLACES_1, 'assets/place/places-1d'],
        [Keys.ATLAS_SUPERSHOP, 'assets/artifacts/Supershop'],
        [Keys.ATLAS_IC_100, 'assets/ic100x100/ic100_b'],
        [Keys.ATLAS_BADGES, 'assets/badge/badge'],
        [Keys.ATLAS_POINTERS, 'assets/pointers/pointer-1d'],
        [Keys.ATLAS_POINTERS_ADD, 'assets/pointers/pointer-add'],
        [Keys.ATLAS_POINTERS_ADD_2, 'assets/pointers/pointer-add-2'],
        [Keys.ATLAS_CONSUMABLES_SHADE, 'assets/consumables/cons_shade'],
        [Keys.ATLAS_CONSUMABLES_NO_SHADE, 'assets/consumables/cons_no_shade'],
        [Keys.ATLAS_GAINABLE, 'assets/gain/gain'],
        [Keys.ATLAS_GAINABLE_2, 'assets/gain/gain2'],
        [Keys.ATLAS_UI_FORCES, 'assets/profile/ui-forces'],
        [Keys.ATLAS_FORCES, 'assets/forces/forces'],
        [Keys.ATLAS_FORCES_6, 'assets/forces/forces-6'],
        [Keys.ATLAS_FORCES_BIG, 'assets/forces/forces-big'],
        [Keys.ATLAS_FORCES_BIG_6, 'assets/forces/forces-big-6'],
        [Keys.ATLAS_FORCES_LARGE, 'assets/forces/forces-large'],
        [Keys.ATLAS_FORCES_LARGE_2, 'assets/forces/forces-large-2'],
        [Keys.ATLAS_FORCES_LARGE_3, 'assets/forces/forces-large-3'],
        [Keys.ATLAS_FORCES_LARGE_6, 'assets/forces/forces-large-6'],
        [Keys.ATLAS_FORCES_SEMI_LARGE, 'assets/forces/forces-semi-large'],
        [Keys.ATLAS_FORCES_SEMI_LARGE_6, 'assets/forces/forces-semi-large-6'],
        [Keys.ATLAS_FORCES_2, 'assets/forces/forces-2'],
        [Keys.ATLAS_FORCES_BIG_2, 'assets/forces/forces-big-2'],
        [Keys.ATLAS_FORCES_5, 'assets/forces/forces-5'],
        [Keys.ATLAS_FORCES_BIG_5, 'assets/forces/forces-big-5'],
        [Keys.ATLAS_RUNES, 'assets/runes/runes'],
        [Keys.ATLAS_RUNES_4, 'assets/runes/runes4'],
        [Keys.ATLAS_UI, 'assets/ui/ui'],
        [Keys.ATLAS_GATHER, 'assets/chain/atlas_gather_6'],

        [Keys.ATLAS_UI_2, 'assets/ui/ui2'],
        [Keys.ATLAS_PORTALS, 'assets/portal/portals_3'],
        [Keys.ATLAS_CREATURES_ROAD_4, 'assets/creatures/creatures-road-4'],
        [Keys.ATLAS_CREATURES_BATTLE_4, 'assets/creatures/creatures-battle-4'],
        [Keys.ATLAS_CREATURES_ROAD_5, 'assets/creatures/creatures-road-5'],
        [Keys.ATLAS_CREATURES_ROAD_8, 'assets/creatures/creatures-road-8'],
        [Keys.ATLAS_CREATURES_ROAD_9, 'assets/creatures/creatures-road-9'],
        [Keys.ATLAS_CREATURES_BATTLE_5, 'assets/creatures/creatures-battle-5'],
        [Keys.ATLAS_CREATURES_BATTLE_7, 'assets/creatures/creatures-battle-7'],
        [Keys.ATLAS_CREATURES_BATTLE_8, 'assets/creatures/creatures-battle-8'],
        [Keys.ATLAS_CREATURES_BATTLE_9, 'assets/creatures/creatures-battle-9'],
        [Keys.ATLAS_CREATURES_BATTLE_10, 'assets/creatures/creatures-battle-10'],
        [Keys.ATLAS_CREATURES_BATTLE_SCEN_8, 'assets/creatures/battle-scen-8'],
        [Keys.ATLAS_HERO_8, 'assets/creatures/hero-8c'],
        [Keys.ATLAS_OF, 'assets/creatures/of2'],
        [Keys.ATLAS_RANK, 'assets/forces/rank-2'],
        [Keys.ATLAS_PLACES_4, 'assets/place/places-4'],
        [Keys.ATLAS_PLACES_5, 'assets/place/places-5'],
        [Keys.ATLAS_PLACES_6, 'assets/place/places-6'],
        [Keys.ATLAS_PLACES_7, 'assets/place/places-7'],
        [Keys.ATLAS_PLACES_8, 'assets/place/places-8'],
        [Keys.ATLAS_PLACES_9, 'assets/place/places-9'],
        [Keys.ATLAS_PLACES_10, 'assets/place/places-10-b'],
        [Keys.ATLAS_PLACES_11, 'assets/place/places-11'],
        [Keys.ATLAS_PLACES_12, 'assets/place/places-12'],
        [Keys.ATLAS_PLACES_13, 'assets/place/places-13'],
        [Keys.ATLAS_PLACES_14, 'assets/place/places-14'],
        [Keys.ATLAS_PLACES_15, 'assets/place/places-15'],
        [Keys.ATLAS_PLACES_16, 'assets/place/places-16'],
        [Keys.ATLAS_PLACES_NY, 'assets/place/places-ny-2'],
        [Keys.ATLAS_PLACES_ZONES_1, 'assets/place/places-zones-1'],
        [Keys.ATLAS_PLACES_ZONES_2, 'assets/place/places-zones-2'],
        [Keys.ATLAS_PLACES_ZONES_3, 'assets/place/places-zones-3'],
        [Keys.ATLAS_PLACES_ELDER, 'assets/place/places-elder'],
        [Keys.ATLAS_ZONE_DRUID, 'assets/place/atlas_zones_zrd_2'],
        [Keys.ATLAS_PLACES_TRIPLE, 'assets/place/places-triple-2'],
        [Keys.ATLAS_PLACES_WATER, 'assets/place/places-nps'],
        [Keys.ATLAS_PLACES_SIDE_QUESTS, 'assets/place/places-sdq'],
        [Keys.ATLAS_PLACES_BIRTHDAY, 'assets/place/places-birthday'],
        ['s_club', 'assets/artifacts/s_club'],
        ['s_cloak2', 'assets/artifacts/s_cloak2'],
        ['s_axe2', 'assets/artifacts/s_axe2'],
        ['s_helmet', 'assets/artifacts/s_helmet'],
        ['s_dice', 'assets/artifacts/s_dice'],
        ['s_ship', 'assets/artifacts/s_ship'],
        ['s_butterfly', 'assets/artifacts/s_butterfly'],
        ['s_rosary', 'assets/artifacts/s_rosary'],

        ['s_saga_sword', 'assets/artifacts/s_saga_sword'],
        ['s_saga_bracers', 'assets/artifacts/s_saga_bracers'],
        ['s_forcety_sword', 'assets/artifacts/s_forcety_sword'],
        ['s_forcety_shield', 'assets/artifacts/s_forcety_shield'],
        ['s_freya_ax', 'assets/artifacts/s_freya_ax'],
        ['s_freya_wings', 'assets/artifacts/s_freya_wings'],
        ['rokkara_ax', 'assets/artifacts/rokkara_ax'],
        ['rokkara_shield', 'assets/artifacts/rokkara_shield'],
        ['s_hed_helmet', 'assets/artifacts/s_hed_helmet'],

        [Keys.ATLAS_CONSUMABLES_SHADE_4, 'assets/consumables/cons_shade_4'],
        [Keys.ATLAS_CONSUMABLES_NO_SHADE_4, 'assets/consumables/cons_no_shade_4'],
        [Keys.ATLAS_CONSUMABLES_SHADE_4X, 'assets/consumables/cons_shade_4x'],
        [Keys.ATLAS_CONSUMABLES_NO_SHADE_4X, 'assets/consumables/cons_no_shade_4x'],
        [Keys.ATLAS_CONS_SHADE_6, 'assets/consumables/cons_shade_6'],
        [Keys.ATLAS_CONS_NO_SHADE_6, 'assets/consumables/cons_no_shade_6'],
        [Keys.ATLAS_PLACES_2, 'assets/place/places-2'],
        [Keys.ATLAS_PLACES_3, 'assets/place/places-3'],
        [Keys.ATLAS_CREATURES_ROAD_2, 'assets/creatures/creatures-road-2'],
        [Keys.ATLAS_CREATURES_BATTLE_2, 'assets/creatures/creatures-battle-2'],
        [Keys.ATLAS_ATL_AVA, 'assets/creatures/avas'],
        [Keys.ATLAS_CONSUMABLES_SHADE_2, 'assets/consumables/cons_shade_2'],
        [Keys.ATLAS_CONSUMABLES_NO_SHADE_2, 'assets/consumables/cons_no_shade_2'],
        [Keys.ATLAS_POINTERS_2, 'assets/pointers/pointer-2'],
        [Keys.ATLAS_POINTERS_3, 'assets/pointers/pointer-3'],
        [Keys.ATLAS_POINTERS_4, 'assets/pointers/pointer-4'],
        [Keys.ATLAS_POINTERS_8, 'assets/pointers/pointer-8'],
        [Keys.ATLAS_POINTERS_SIDE_QUESTS, 'assets/pointers/pointer-sdq'],
        ['S_Boots', 'assets/artifacts/S_Boots'],
        ['S_Armor', 'assets/artifacts/S_Armor'],
        ['S_Bow', 'assets/artifacts/S_Bow'],
        ['S_Shield', 'assets/artifacts/S_Shield'],
        ['shuriken', 'assets/artifacts/shuriken'],
        ['horseshoe', 'assets/artifacts/horseshoe'],
        ['s_harp', 'assets/artifacts/s_harp']
    ]);

    static initialize() {
        for (let road of Keys.ROADS) {
            Urls.IMAGES.set(road, `assets/bg/${road}.jpg`);
        }

        for (let road of Keys.ROADS_WEBP) {
            Urls.IMAGES.set(road, `assets/bg/${road}.webp`);
        }

        for (let road of Keys.ROADS_UI) {
            Urls.IMAGES.set(road, `assets/bg/ui2/${road}.jpg`);
        }

        for (let zone of Keys.ZONES) {
            Urls.IMAGES.set(zone, `assets/bg/zones/${zone}.jpg`);
        }

        for (let road of Keys.ROAD_MAIN) {
            Urls.IMAGES.set(road, `assets/bg/main2/${road}.webp`);
        }

        for (let g of Keys.ADDITIONAL_GAINABLES) {
            Urls.IMAGES.set(g, `assets/gain/${g}.png`);
        }

        for (let place of Keys.ADDITIONAL_PLACES) {
            Urls.IMAGES.set(place, `assets/place/${place}.png`);
        }

        for (let r of Keys.REPLICS) {
            Urls.IMAGES.set(r, `assets/replics/${r}.png`);
        }

        for (let anim of Keys.ANIMS) {
            Urls.IMAGES.set(anim, `assets/anim/${anim}`);
            // , { frameWidth: size.x, frameHeight: size.y }
        }

        for (let road of Keys.ROADS_2) {
            Urls.IMAGES.set(road, `assets/bg/${road}.jpg`);
        }

        for (let i = 0; i < 25; ++i) {

            Urls.FRAME_CONFIGS.set(Keys.STAIRS_YELLOW[i], {frameWidth: 33, frameHeight: 16});
            Urls.FRAME_CONFIGS.set(Keys.STAIRS_GREEN[i], {frameWidth: 33, frameHeight: 16});
            Urls.FRAME_CONFIGS.set(Keys.STAIRS_RED[i], {frameWidth: 33, frameHeight: 16});

            Urls.ATLASES.set(Keys.STAIRS_YELLOW[i], 'assets/stair/yellow_s' + i);
            Urls.ATLASES.set(Keys.STAIRS_GREEN[i], 'assets/stair/green_s' + i);
            Urls.ATLASES.set(Keys.STAIRS_RED[i], 'assets/stair/red_s' + i);

        }
    }

}

Urls.initialize();